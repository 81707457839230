import { createContext, useContext, useState } from "react";
import { useLocalStorage } from "../hooks/useStorage";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser, removeUser] = useLocalStorage("user", null);
  const [chat, setChat, removeChat] = useLocalStorage("chat", []);

  const logout = () => {
    removeUser();
  };

  const setLoggedInUser = (user) => {
    setUser(user);
    setChat([]);
  };

  return (
    <UserContext.Provider
      value={{ user, setLoggedInUser, logout, chat, setChat, removeChat }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  return useContext(UserContext);
};

export { UserProvider, useUserContext };
