import React from "react";
import PageWrapperComponent from "../components/common/PageWrapper.component";
import DashboardComponent from "../components/dashboard/Dashboard.component";

const DashboardPage = () => {
  return (
    <PageWrapperComponent>
      <DashboardComponent />
    </PageWrapperComponent>
  );
};

export default DashboardPage;
