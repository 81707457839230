import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import { useDialogContext } from "../../context/useDialogContext";
import { useUserContext } from "../../context/useAppContext";

import InputComponent from "../common/Input.component";
import ButtonComponent from "../common/Button.component";
import PVHComponent from "../common/PVH.component";

import microsoft from "../../assets/microsoft.png";

import apiModules from "../../api/service";
import { BUTTON_TYPE, DIALOG_TYPE } from "../../constants";
import { useLoaderContext } from "../../context/useLoaderContext";

const LoginComponent = () => {
  const navigate = useNavigate();
  const { setLoggedInUser } = useUserContext();
  const { showDialog } = useDialogContext();
  const { setLoading } = useLoaderContext();

  const [login, setLogin] = useState({ email: "", password: "" });

  const {
    mutate,
    data: response,
    isLoading,
  } = useMutation({
    mutationFn: (user) => apiModules.login(user),
  });

  useEffect(() => {
    if (response) {
      const { data } = response;

      if (data) {
        if (data.errors) {
          showDialog(data.errors.message, DIALOG_TYPE.ERROR);
        } else {
          setLoggedInUser(data);
          if (data.role === "Admin") {
            navigate("/", { replace: true });
          } else {
            navigate("/chat", { replace: true });
          }
        }
      }
    }
  }, [response]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="w-[30vw] h-[70vh] bg-white flex flex-col items-center justify-center p-8">
        <PVHComponent />
        <div className="flex w-full flex-col gap-4 mt-8">
          <InputComponent
            type="text"
            label="Email"
            value={(e) => {
              setLogin({ ...login, email: e });
            }}
          />
          <InputComponent
            type="password"
            label="Password"
            value={(e) => {
              setLogin({ ...login, password: e });
            }}
          />
          <ButtonComponent
            label="SIGN IN"
            variant={BUTTON_TYPE.CONTAINED}
            className="mb-8"
            onClick={() =>
              mutate({ email: login.email, password: login.password })
            }
          />
        </div>
        <div className="flex flex-col text-xs items-center gap-1 mb-4">
          <p
            className="text-gray-900 cursor-pointer"
            onClick={() => showDialog(null, DIALOG_TYPE.ACCESS_DENIED)}
          >
            Forget Password?
          </p>
          <p className="text-gray-600 ">
            Not yet a member?
            <span
              className="text-gray-900 cursor-pointer ms-2"
              onClick={() => showDialog(null, DIALOG_TYPE.ACCESS_DENIED)}
            >
              Sign Up
            </span>
          </p>
        </div>
        <div className="flex w-full justify-center items-center mb-4">
          <div className="flex-1 h-[1px] bg-gray-300"></div>
          <p className="flex-1 text-center text-xs text-gray-600">
            Connect with
          </p>
          <div className="flex-1 w-full h-[1px] bg-gray-300"></div>
        </div>
        <div
          className="flex w-full py-1.5 items-center justify-center border border-gray-300 rounded-none cursor-pointer"
          onClick={() => showDialog(null, DIALOG_TYPE.ACCESS_DENIED)}
        >
          <img
            src={microsoft}
            className="w-5 h-5 m-1 me-3 text-gray-900"
            alt="Microft Logo"
          />
          <p className="text-xs">Sign in with Microsoft</p>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
