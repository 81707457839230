import React from "react";

const LoadingSpinnerComponent = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className="absolute bg-black bg-opacity-75 transition-opacity w-screen h-screen z-10 left-0 top-0">
        <p
          className={`absolute -translate-x-1/2 -translate-y-1/2 text-white left-1/2 top-1/2 ${
            isLoading ? "" : "hidden"
          }`}
        >
          Loading
        </p>
      </div>
    );
  }
};

export default LoadingSpinnerComponent;
