import React from "react";

import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineUserCircle } from "react-icons/hi2";

import ButtonComponent from "../common/Button.component";
import { BUTTON_TYPE, DIALOG_TYPE } from "../../constants";
import { useDialogContext } from "../../context/useDialogContext";

const UserDetailsComponent = ({ user }) => {
  const { showDialog } = useDialogContext();

  return (
    <div className="flex p-8 flex-col w-full">
      <HiOutlineUserCircle size={64} />
      <p className="text-gray-900 text-xl mt-2">{user?.name}</p>
      <p className="text-gray-500 text-md">{user?.employeeId}</p>
      <p className="text-gray-700 text-md">{user?.department}</p>
      <div className="w-full h-[1px] bg-gray-300 my-3"></div>
      <div className="flex text-gray-700 my-1">
        <p className="w-44">Email</p>
        <p className="">:</p>
        <p className="flex-1 mx-2">{user?.email}</p>
      </div>
      <div className="flex text-gray-700 my-1">
        <p className="w-44">Phone Number</p>
        <p className="">:</p>
        <p className="flex-1 mx-2">{user?.contact}</p>
      </div>
      <div className="w-full h-[1px] bg-gray-300 my-3"></div>
      <div className="flex text-gray-700 my-1">
        <p className="w-44">Joined Date</p>
        <p className="">:</p>
        <p className="flex-1 mx-2">{user?.joined}</p>
      </div>
      <div className="flex text-gray-700 my-1">
        <p className="w-44">Role</p>
        <p className="">:</p>
        <p className="flex-1 mx-2">{user?.role}</p>
      </div>
      <div className="w-full h-[1px] bg-gray-300 my-3"></div>
      <div className="flex gap-2">
        <ButtonComponent
          label="EDIT"
          className="px-4"
          icon={<FiEdit />}
          onClick={() => {
            showDialog(null, DIALOG_TYPE.ACCESS_DENIED);
          }}
        />
        <ButtonComponent
          label="DELETE"
          variant={BUTTON_TYPE.OUTLINED}
          className="px-4"
          icon={<RiDeleteBin6Line />}
          onClick={() => {
            showDialog(null, DIALOG_TYPE.ACCESS_DENIED);
          }}
        />
      </div>
    </div>
  );
};

export default UserDetailsComponent;
