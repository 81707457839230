import React, { createContext, useContext, useState } from "react";

import { DIALOG_TYPE } from "../constants";
import DialogComponent from "../components/common/Dialog.component";

const DialogContext = createContext();

const INITIAL_STATE = {
  message: "",
  variant: null,
};

const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = useState(INITIAL_STATE);

  const showDialog = (message = "", variant = DIALOG_TYPE.DEFAULT) => {
    setDialog({ message, variant });
  };

  const closeDialog = () => setDialog(INITIAL_STATE);

  return (
    <DialogContext.Provider value={{ showDialog, closeDialog }}>
      <DialogComponent
        message={dialog.message}
        variant={dialog.variant}
        onClose={closeDialog}
      />
      {children}
    </DialogContext.Provider>
  );
};

const useDialogContext = () => {
  return useContext(DialogContext);
};

export { DialogProvider, useDialogContext };
