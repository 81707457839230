import React from "react";

const DocumentHeaderComponent = () => {
  return (
    <thead class="text-xs text-gray-800 uppercase bg-gray-50">
      <tr>
        <th scope="col" class="px-2 py-3">
          ID
        </th>
        <th scope="col" class="px-2 py-3">
          Document Name
        </th>
        <th scope="col" class="px-2 py-3  ">
          Category
        </th>
        <th scope="col" class="px-2 py-3 text-center">
          Type
        </th>
        <th scope="col" class="px-2 py-3 ">
          Upload Date
        </th>
        <th scope="col" class="px-2 py-3">
          Upload Time
        </th>
        <th scope="col" class="px-2 py-3 w-2"></th>
        <th scope="col" class="px-2 py-3 w-2"></th>
      </tr>
    </thead>
  );
};

export default DocumentHeaderComponent;
