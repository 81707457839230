import React from "react";
import Lottie from "lottie-react";
import restrictedAnimation from "../../assets/restricted.json";

const TrainingComponent = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Lottie
        animationData={restrictedAnimation}
        loop={true}
        className="w-60"
      />
      <p className="text-2xl font-medium">Access Denied</p>
    </div>
  );
};

export default TrainingComponent;
