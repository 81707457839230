const DOCUMENT_ITEMS = [
  {
    id: "001",
    name: "Training Document - May 2023",
    category: "Work Day",
    type: "pdf",
    date: "06/11/2023",
    time: "10:10:10",
  },
  {
    id: "002",
    name: "Training Document - June 2023",
    category: "Share Point",
    type: "word",
    date: "06/11/2023",
    time: "10:10:10",
  },
  {
    id: "003",
    name: "Training Document - June 2023",
    category: "Work Day",
    type: "pdf",
    date: "06/11/2023",
    time: "10:10:10",
  },
];

const USERS = [
  {
    id: "2",
    name: "John Doe",
    employeeId: "Emp001 - Employee",
    department: "HR Department",
    email: "johndeo@gmail.com",
    contact: "23432432",
    joined: "10/10/2023",
    role: "User",
  },
  {
    id: "3",
    name: "John Doe",
    employeeId: "Emp001 - Employee",
    department: "HR Department",
    email: "johndeo@gmail.com",
    contact: "23432432",
    joined: "10/10/2023",
    role: "User",
  },
  {
    id: "4",
    name: "John Doe",
    employeeId: "Emp001 - Employee",
    department: "HR Department",
    email: "johndeo@gmail.com",
    contact: "23432432",
    joined: "10/10/2023",
    role: "User",
  },
  {
    id: "5",
    name: "John Doe",
    employeeId: "Emp001 - Employee",
    department: "HR Department",
    email: "johndeo@gmail.com",
    contact: "23432432",
    joined: "10/10/2023",
    role: "User",
  },
];

const CHAT = [
  {
    id: 1,
    isSender: true,
    message:
      "What are the two components of the people management framework at PVH?",
    timestamp: "10/10/2034",
  },
  {
    id: 2,
    isSender: false,
    message:
      "The two components of the people management framework at PVH are Lead and Support",
    timestamp: "10/10/2034",
  },
  {
    id: 3,
    isSender: true,
    message:
      "How does the quality of the manager impact team engagement and retention?",
    timestamp: "10/10/2034",
  },
  {
    id: 4,
    isSender: false,
    message:
      "Research from Gartner shows that the way these moments are handled has a profound influence on the associate experience, and ultimately impacts their performance, morale and retention. A Gallup study revealed that employees who feel as though their manager is invested in them as people are more likely to be engaged. The best people managers build genuine relationships with associates and create recurring meaningful interactions over time.",
    timestamp: "10/10/2034",
  },
  {
    id: 5,
    isSender: true,
    message:
      "What is the name of the training course that focuses on the Lead component of the people management framework?",
    timestamp: "10/10/2034",
  },
  {
    id: 6,
    isSender: false,
    message: "Rise with Leadership Behaviors",
    timestamp: "10/10/2034",
  },
];

export { DOCUMENT_ITEMS, USERS, CHAT };
