import React from "react";
import { SlScreenDesktop } from "react-icons/sl";

const RestrictDesktopView = ({ children }) => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const isTablet = window.matchMedia(
    "(min-width: 769px) and (max-width: 1024px)"
  ).matches;
  return (
    <>
      {isMobile || isTablet ? (
        <div className="flex flex-col gap-4 justify-center  w-screen h-screen p-8">
          <SlScreenDesktop size={64} />
          <p className="text-xl">
            Temporary Restrictions on Mobile and Tablet Access
            <br /> <br />
            We're currently limiting access to mobile and tablet views during
            our proof-of-concept (POC) phase
            <br /> <br />
            Thank you for your understanding.
          </p>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default RestrictDesktopView;
