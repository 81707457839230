import React from "react";
import { RiRobot2Line } from "react-icons/ri";
import { RiUser3Line } from "react-icons/ri";
import { useUserContext } from "../../context/useAppContext";

const ChatBubbleComponent = ({ isSender, message }) => {
  const { user } = useUserContext();

  return (
    <div
      className={`w-full flex ${isSender ? "justify-end" : "justify-start"}`}
    >
      {isSender ? (
        <div className="bg-gray-100 px-5 py-6 max-w-xl flex flex-col items-end gap-3 rounded-l-md">
          <span className="flex flex-row-reverse items-center gap-1">
            <RiUser3Line className="ml-2" /> User
          </span>
          <p className="text-sm">{message}</p>
        </div>
      ) : (
        <div className="bg-gray-100 px-5 py-6  w-3/4 flex flex-col gap-3 rounded-r-md">
          <span className="flex flex-row items-center gap-1">
            <RiRobot2Line className="mr-2" /> AI
          </span>
          <p className="text-sm">{message}</p>
          <p className="text-xs text-gray-300 cursor-pointer hover:underline hover:text-gray-400">
            Report
          </p>
        </div>
      )}
    </div>
  );
};

export default ChatBubbleComponent;
