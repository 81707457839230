import React from "react";
import { MdClose } from "react-icons/md";

import { DIALOG_TYPE } from "../../constants";
import AccessDeniedComponent from "./dialog/AccessDenied.component";
import ErrorComponent from "./dialog/Error.component";

const DialogComponent = ({ message = null, variant = null, onClose }) => {
  return (
    <div className="relative">
      {variant && (
        <div className="absolute bg-black bg-opacity-75 transition-opacity w-screen h-screen z-10 left-0 top-0">
          <div className="drop-shadow-2xl fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 h-68 bg-gray-50 flex flex-col p-4 items-center">
            <div className="flex flex-col w-full items-end ">
              <button className="" onClick={() => onClose()}>
                <MdClose size={24} className="text-gray-900" />
              </button>
            </div>
            {message && variant === DIALOG_TYPE.DEFAULT}
            {variant === DIALOG_TYPE.ACCESS_DENIED && <AccessDeniedComponent />}
            {variant === DIALOG_TYPE.ERROR && (
              <ErrorComponent message={message} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DialogComponent;
