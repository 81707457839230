import React from "react";

const PVHComponent = () => {
  return (
    <div className="h-12 w-12 bg-black text-white flex align-bottom justify-center items-end pb-1">
      <p className="text-sm font-bold">PVH</p>
    </div>
  );
};

export default PVHComponent;
