import { LuLayoutDashboard } from "react-icons/lu";
import { IoFolderOutline } from "react-icons/io5";
import { HiOutlineUsers } from "react-icons/hi2";
import { MdOutlineShield } from "react-icons/md";

const DRAWER_ITEMS = [
  { label: "Dashboard", icon: <LuLayoutDashboard />, url: "/" },
  { label: "Document Manager", icon: <IoFolderOutline />, url: "/document" },
  { label: "User Manager", icon: <HiOutlineUsers />, url: "/manageUser" },
  { label: "Training", icon: <MdOutlineShield />, url: "/training" },
];

export { DRAWER_ITEMS };
