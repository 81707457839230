import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserContext } from "../context/useAppContext";

const ProtectedRoute = ({ children }) => {
  const { user } = useUserContext();
  const { pathname } = useLocation();

  if (user?.role === "User" && pathname !== "/chat") {
    return <Navigate to={"/unauthorized"} />;
  }

  if (user?.role === "Admin" && pathname === "/chat") {
    return <Navigate to={"/unauthorized"} />;
  }

  if (user) {
    return children;
  }
  return <Navigate to={"/login"} />;
};
export default ProtectedRoute;
