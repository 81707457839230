import React, { useEffect, useRef, useState } from "react";
import { MdSend } from "react-icons/md";
import ChatBubbleComponent from "./ChatBubble.component";
import { useMutation } from "react-query";
import apiModules from "../../api/service";
import { useDialogContext } from "../../context/useDialogContext";
import { DIALOG_TYPE } from "../../constants";
import { useUserContext } from "../../context/useAppContext";

const ChatBotComponent = () => {
  const chatContainerRef = useRef(null);
  const { showDialog } = useDialogContext();
  const [question, setQuestion] = useState("");
  const { chat, setChat } = useUserContext();
  const [isWaiting, setWaiting] = useState(false);

  const {
    mutate,
    data: response,
    isLoading,
  } = useMutation({
    mutationFn: (question) => apiModules.getAnswer(question),
  });

  useEffect(() => {
    if (response) {
      const { data } = response;

      if (data) {
        if (data.errors) {
          showDialog(data.errors.message, DIALOG_TYPE.ERROR);
        } else {
          setChat([
            ...chat,
            {
              id: chat.length + 1,
              isSender: false,
              message: data?.answer,
              timestamp: new Date().toLocaleDateString("en-US"),
            },
          ]);
        }
      }
      setWaiting(false);
    }
  }, [response]);

  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    scrollToLastChat();
  }, [chat]);

  const scrollToLastChat = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  const clickHandler = (e) => {
    if (question && question.length > 0) {
      if (!isWaiting) {
        console.log(question);
        mutate({ question: question });
        setWaiting(true);
        setQuestion("");
        setChat([
          ...chat,
          {
            id: chat.length + 1,
            isSender: true,
            message: question,
            timestamp: new Date().toLocaleDateString("en-US"),
          },
        ]);
      }
    }
  };

  return (
    <div className="bg-white w-full flex h-[calc(100vh-64px)] pb-8 flex-col-reverse items-center">
      <div className="flex justify-center w-3/4 ">
        <div className="relative w-full flex py-2 items-center">
          <input
            type="text"
            placeholder="Ask your question"
            className="bg-gray-50  p-3 px-4 w-full h-16 focus:outline-none"
            value={question}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                clickHandler();
              }
            }}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
          <span
            className="cursor-pointer absolute left-[95%]"
            onClick={clickHandler}
          >
            {isLoading ? (
              <div className="flex space-x-1 justify-center items-center bg-white dark:invert">
                <div className="h-1 w-1 bg-gray-900 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="h-1 w-1 bg-gray-900 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="h-1 w-1 bg-gray-900 rounded-full animate-bounce"></div>
              </div>
            ) : (
              <MdSend className="text-gray-900 hover:text-gray-800" />
            )}
          </span>
        </div>
      </div>

      <div
        ref={chatContainerRef}
        className="flex flex-col w-3/4 gap-4 h-full overflow-y-auto py-4 transition-all duration-500"
      >
        {chat.map((item) => {
          return (
            <ChatBubbleComponent
              key={item.id}
              isSender={item.isSender}
              message={item.message}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChatBotComponent;
