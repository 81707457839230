import React from "react";
import Lottie from "lottie-react";

import errorAnimation from "../../../assets/error.json";

const ErrorComponent = ({ message }) => {
  return (
    <div className="w-full h-full flex flex-col items-center my-2">
      <Lottie animationData={errorAnimation} loop={true} className="w-20" />
      <p className="text-lg font-bold mb-2 text-red-400 uppercase">ERROR</p>
      <p className="text-sm font-medium h-12  text-center text-gray-500">
        {message}
      </p>
    </div>
  );
};

export default ErrorComponent;
