import React from "react";
import PageWrapperComponent from "../components/common/PageWrapper.component";
import DocumentComponent from "../components/documentManager/Document.component";

const DocumentPage = () => {
  return (
    <PageWrapperComponent>
      <DocumentComponent />
    </PageWrapperComponent>
  );
};

export default DocumentPage;
