import { ftl_ai, pvh_api } from "../utils/config";

const apiModules = {
  login: (user) =>
    pvh_api.post("/login", {
      email: user.email,
      password: user.password,
    }),
  registerUser: (user) => ftl_ai.post("/register", user),
  uploadDocument: (document) =>
    ftl_ai.post("upload", document, {
      headers: {
        Accept: "*/*",
        "Content-Type": "",
      },
    }),
  fetchDocuments: () => pvh_api.get("/fetchAllDocuments"),
  fetchUsers: () => pvh_api.get("/fetchAllUsers"),
  fetchLogs: () => ftl_ai.get("/logs/all"),
  getAnswer: (question) => ftl_ai.post("/answer", question),
};

export default apiModules;
