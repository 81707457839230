import React from "react";
import { useDialogContext } from "../../context/useDialogContext";
import { DIALOG_TYPE } from "../../constants";

const DocumentBodyComponent = ({ document }) => {
  const { showDialog } = useDialogContext();

  return (
    <tr class="bg-white border-b text-gray-700">
      <td class="px-2 py-4 w-8">{document.id}</td>
      <td class="px-2 py-4 text-start">{document.name}</td>
      <td class="px-2 py-4 w-32 text-start">{document.category}</td>
      <td class="px-2 py-4 w-32 text-center uppercase">{document.type}</td>
      <td class="px-2 py-4 w-32 text-start">{document.date}</td>
      <td class="px-2 py-4 w-28 text-start">{document.time}</td>
      <td
        class="px-3 py-4 w-2 text-red-500 cursor-pointer"
        onClick={() => {
          showDialog(null, DIALOG_TYPE.ACCESS_DENIED);
        }}
      >
        DELETE
      </td>
      <td
        class="px-3 py-4 w-2 text-gray-900 cursor-pointer"
        onClick={() => {
          showDialog(null, DIALOG_TYPE.ACCESS_DENIED);
        }}
      >
        PREVIEW
      </td>
    </tr>
  );
};

export default DocumentBodyComponent;
