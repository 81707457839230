import React from "react";
import LoginComponent from "../components/auth/Login.component";
import ScreenWrapperComponent from "../components/common/ScreenWrapper.component";

const LoginPage = () => {
  return (
    <ScreenWrapperComponent>
      <LoginComponent />
    </ScreenWrapperComponent>
  );
};

export default LoginPage;
