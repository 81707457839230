import React from "react";
import ScreenWrapperComponent from "../components/common/ScreenWrapper.component";
import ChatBotComponent from "../components/chatBot/ChatBot.component";

const ChatPage = () => {
  return (
    <ScreenWrapperComponent>
      <ChatBotComponent />
    </ScreenWrapperComponent>
  );
};

export default ChatPage;
