import axios from "axios";

const ftl_ai = axios.create({
  // baseURL: "https://pvhbotapi.forzotechlabs.com/",
  baseURL: "https://je35we7zp1.execute-api.us-east-2.amazonaws.com/dev/",
  headers: {
    // "Content-type": "application/json",
    "access-control-allow-origin": "*",
  },
});

const pvh_api = axios.create({
  baseURL:
    "https://5wfrs4cvad.execute-api.us-east-2.amazonaws.com/dev-serverless/",
  headers: {
    "Content-type": "application/json",
    "access-control-allow-origin": "*",
  },
});

export { ftl_ai, pvh_api };
