import React, { useEffect, useState } from "react";

import UserDetailsComponent from "./UserDetails.component";
import { USERS } from "../../constants/data";
import UserListComponent from "./UserList.component";
import apiModules from "../../api/service";
import { useQuery } from "react-query";
import { DIALOG_TYPE } from "../../constants";
import { useDialogContext } from "../../context/useDialogContext";

const UserComponent = () => {
  const { showDialog } = useDialogContext();
  const [users, setUsers] = useState(USERS);
  const [selectedUser, setSelectedUser] = useState();

  const fetchUsers = async () => {
    try {
      const response = await apiModules.fetchUsers();
      return response.data;
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const { data, error } = useQuery("users", fetchUsers);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (error) {
      showDialog(error, DIALOG_TYPE.ERROR);
    } else if (data) {
      setUsers(data);
      setSelectedUser(data[0]);
    }
  }, [error, data]);

  return (
    <div className="flex w-full h-full">
      <div className="w-96 border-e-2 ">
        <UserListComponent
          users={users}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </div>
      <div className="flex-1">
        <UserDetailsComponent user={selectedUser} />
      </div>
    </div>
  );
};

export default UserComponent;
