import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { CiLogout } from "react-icons/ci";
import { HiOutlineUserCircle } from "react-icons/hi2";

import { useUserContext } from "../../context/useAppContext";
import PVHComponent from "./PVH.component";
import { useDialogContext } from "../../context/useDialogContext";
import { DIALOG_TYPE } from "../../constants";

const NavbarComponent = () => {
  const { user, logout, removeChat } = useUserContext();
  const { showDialog } = useDialogContext();
  const navigate = useNavigate();

  return (
    <nav className="bg-white w-full h-16 flex justify-between items-center border-b-[1px] border-black px-2">
      <Link to={`${user?.role === "Admin" ? "/" : "/chat"}`} className="px-4">
        <PVHComponent />
      </Link>
      <div className="flex items-center gap-4 mx-4">
        <div
          className="cursor-pointer"
          onClick={() => {
            showDialog(null, DIALOG_TYPE.ACCESS_DENIED);
          }}
        >
          <HiOutlineUserCircle size={24} />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            logout();
            removeChat();
            navigate("/login", { replace: true });
          }}
        >
          <CiLogout size={24} />
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
