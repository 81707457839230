import React from "react";
import Lottie from "lottie-react";

import invalidAnimation from "../assets/404.json";
import ScreenWrapperComponent from "../components/common/ScreenWrapper.component";
import NavbarComponent from "../components/common/Navbar.component";

const InvalidPage = () => {
  return (
    <ScreenWrapperComponent>
      <NavbarComponent />
      <div className="flex bg-white w-full h-full justify-center items-center">
        <Lottie animationData={invalidAnimation} loop={true} className="w-96" />
      </div>
    </ScreenWrapperComponent>
  );
};

export default InvalidPage;
