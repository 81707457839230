import React from "react";
import { Outlet } from "react-router-dom";
import NavbarComponent from "../components/common/Navbar.component";
import DrawerComponent from "../components/common/Drawer.component";
import ScreenWrapperComponent from "../components/common/ScreenWrapper.component";
import { useUserContext } from "../context/useAppContext";

const SharedLayout = () => {
  const { user } = useUserContext();
  return (
    <ScreenWrapperComponent>
      <div className="flex h-full">
        {user?.role === "Admin" && <DrawerComponent />}
        <div className="flex flex-col w-full h-full">
          <NavbarComponent />
          <Outlet />
        </div>
      </div>
    </ScreenWrapperComponent>
  );
};

export default SharedLayout;
