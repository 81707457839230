import React from "react";

import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";

import { MdOutlineChevronLeft } from "react-icons/md";
import { MdOutlineChevronRight } from "react-icons/md";

const getPages = (pages, active) => {
  const arr = [];
  for (let i = 1; i <= pages; i++) {
    arr.push(
      <li>
        <div
          className={`flex items-center justify-center w-7 h-7 rounded-none cursor-pointer ms-1 ${
            active === i ? "text-gray-900 font-bold " : "text-gray-600 "
          }`}
        >
          {i}
        </div>
      </li>
    );
  }
  return arr;
};

const PaginationComponent = ({ pages, active }) => {
  return (
    <nav>
      <ul class="flex items-center h-6 text-sm">
        <li>
          <div class="flex items-center justify-center w-7 h-7 rounded-none text-gray-900  border-gray-50 cursor-pointer">
            <MdOutlineChevronLeft size={14} />
          </div>
        </li>

        {getPages(pages, active)}

        <li>
          <div class="flex items-center justify-center w-7 h-7 rounded-none text-gray-900   border-gray-50 cursor-pointer">
            <MdOutlineChevronRight size={14} />
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationComponent;
