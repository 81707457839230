import React from "react";
import PageWrapperComponent from "../components/common/PageWrapper.component";
import UserComponent from "../components/userManager/User.component";

const ManageUserPage = () => {
  return (
    <PageWrapperComponent>
      <UserComponent />
    </PageWrapperComponent>
  );
};

export default ManageUserPage;
