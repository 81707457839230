import React, { createContext, useContext, useState } from "react";

import LoadingSpinnerComponent from "../components/common/LoadingSpinner.component";

const LoaderContext = createContext();

const LoaderProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => setLoading(false);

  return (
    <LoaderContext.Provider value={{ startLoading, stopLoading, setLoading }}>
      <LoadingSpinnerComponent isLoading={isLoading} />
      {children}
    </LoaderContext.Provider>
  );
};

const useLoaderContext = () => {
  return useContext(LoaderContext);
};

export { LoaderProvider, useLoaderContext };
