import React, { useState } from "react";
import { LuUpload } from "react-icons/lu";

import ButtonComponent from "../common/Button.component";
import DocumentHeaderComponent from "./DocumentHeader.component";
import DocumentBodyComponent from "./DocumentBody.component";
import { DOCUMENT_ITEMS } from "../../constants/data";
import PaginationComponent from "../common/Pagination.component";
import FileUploadComponent from "../common/FileUpload.component";
import apiModules from "../../api/service";
import { useQuery } from "react-query";

const DocumentComponent = () => {
  const [uploadModal, setUploadModal] = useState(false);
  const [documents, setDocuments] = useState(null);
  const fetchDocuments = async () => {
    try {
      const response = await apiModules.fetchDocuments();
      return response.data;
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  const { data, error } = useQuery("documents", fetchDocuments, {
    onSuccess: (documents) => {
      setDocuments(documents);
    },
  });

  return (
    <div className="flex flex-col w-full h-full">
      <FileUploadComponent open={uploadModal} setModal={setUploadModal} />
      <div className="w-full flex justify-between items-center mb-4">
        <p className="text-md">Documents</p>
        <ButtonComponent
          label="UPLOAD"
          icon={<LuUpload />}
          onClick={() => setUploadModal(true)}
        />
      </div>
      <div className="flex-1">
        <div class="relative overflow-x-auto ">
          <table class="w-full text-sm text-left text-gray-500">
            <DocumentHeaderComponent />
            <tbody>
              {documents
                ? documents.map((document) => (
                    <DocumentBodyComponent document={document} />
                  ))
                : "Loading"}
            </tbody>
          </table>
        </div>
      </div>
      <div className="border p-2 w-full flex justify-end bg-gray-200">
        <PaginationComponent pages={5} active={1} />
      </div>
    </div>
  );
};

export default DocumentComponent;
