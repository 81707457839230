import React, { useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";

import UserItemComponent from "./UserItem.component";
import InputComponent from "../common/Input.component";
import { useDialogContext } from "../../context/useDialogContext";
import { DIALOG_TYPE } from "../../constants";

const UserListComponent = ({ users, selectedUser, setSelectedUser }) => {
  const { showDialog } = useDialogContext();
  const [searchText, setSearchText] = useState("");

  return (
    <div className="w-full h-full flex flex-col pe-4">
      <div className="w-full flex justify-between items-center mb-4">
        <p className="text-gray-900 text-md">Users</p>
        <CiCirclePlus
          size={20}
          className="cursor-pointer"
          onClick={() => {
            showDialog(null, DIALOG_TYPE.ACCESS_DENIED);
          }}
        />
      </div>
      <div className="flex-1 flex flex-col bg-gray-100">
        <div className="flex w-full gap-2">
          <InputComponent
            type="text"
            label="Search"
            className="w-full border-0 m-4"
            value={(e) => {
              setSearchText(e);
            }}
            icon={<IoIosSearch size={20} className="text-gray-500" />}
          />
        </div>
        <div className="flex flex-col mt-4">
          {users?.length > 0
            ? users.map((user) => (
                <UserItemComponent
                  user={user}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
              ))
            : "Loading"}
        </div>
      </div>
    </div>
  );
};

export default UserListComponent;
