const InputComponent = ({ label, type, className, icon = null, value }) => {
  const handleChange = (e) => {
    value(e.target.value);
  };
  return (
    <div className="flex w-full justify-center items-center relative">
      <input
        onInput={(e) => {
          handleChange(e);
        }}
        type={type}
        placeholder={label}
        className={`flex-1 p-1.5 px-2 border border-gray-300 rounded-none focus-visible:outline-none focus-visible:border-black focus-visible:rounded-none ${className}`}
      />
      {icon !== null ? <div className="absolute end-6">{icon}</div> : ""}
    </div>
  );
};

export default InputComponent;
