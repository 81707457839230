import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { RiUploadCloud2Line } from "react-icons/ri";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FiLoader } from "react-icons/fi";
import { FaRegFilePdf } from "react-icons/fa6";
import { useLoaderContext } from "../../context/useLoaderContext";

const INITIAL_DOCUMENT = {
  file: null,
  name: "",
  type: "",
};

const FileUploadComponent = ({ open, setModal }) => {
  const { startLoading, stopLoading } = useLoaderContext();
  const [document, setDocument] = useState(INITIAL_DOCUMENT);

  const { mutate, error, isLoading, data } = useMutation((formData) => {
    return axios.post("https://pvhbotapi.forzotechlabs.com/upload", formData, {
    // return axios.post("http://localhost:4000/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 1000 * 600,
    });
  });

  useEffect(() => {
    if (isLoading) startLoading();
    else stopLoading();
    console.log("error", error);
    if (error) {
      console.log("errror here", error);

      console.log(error);
      setDocument(INITIAL_DOCUMENT);
      setModal(false);
      // data.data = null;
    }
  }, [isLoading, error]);

  const handleFileSelected = async () => {
    if (document) {
      const formData = new FormData();
      formData.append("file", document.file);
      formData.append("name", document.name);
      formData.append("type", document.type);
      mutate(formData);
    }
  };

  return (
    <>
      {open && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-gray-900 px-4 py-3 sm:flex sm:flex-row sm:px-6">
                  <h3
                    className="text-base font-semibold leading-6 text-white my-1"
                    id="modal-title"
                  >
                    Upload Document
                  </h3>
                </div>

                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-1 text-center sm:ml-4 sm:mt-0 sm:text-left w-full ">
                      <div className="mt-1">
                        <div className="flex flex-col gap-8 ">
                          <div className="flex items-center justify-center w-full">
                            <label
                              for="dropzone-file"
                              className="flex flex-col  items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                              <div className="flex flex-row gap-3 items-center justify-center pt-5 pb-6">
                                <RiUploadCloud2Line
                                  size={24}
                                  className="text-gray-500"
                                />
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">
                                    Click to upload
                                  </span>
                                  or drag and drop
                                </p>
                              </div>
                              <input
                                id="dropzone-file"
                                type="file"
                                className="hidden"
                                accept="application/pdf"
                                onChange={(e) => {
                                  const selectedFile = e.target.files[0];
                                  setDocument({
                                    file: selectedFile,
                                    name: selectedFile?.name,
                                    fileType: selectedFile?.type,
                                  });
                                }}
                              />
                            </label>
                          </div>
                          <div className="flex flex-col gap-1">
                            {document?.name && (
                              <p className="flex flex-row items-center gap-2">
                                <span>
                                  <FaRegFilePdf />
                                </span>
                                {document.name}
                              </p>
                            )}

                            <div className="flex flex-row items-center gap-1">
                              {data?.data?.status && !isLoading && (
                                <>
                                  <div className="w-full h-4 flex justify-between items-center rounded-full">
                                    <div className="bg-green-500  w-full h-full"></div>
                                  </div>
                                  <IoCheckmarkCircleOutline className="text-green-500" />
                                </>
                              )}

                              {isLoading && (
                                <>
                                  <div className="w-full h-4 flex justify-between items-center rounded-full">
                                    <div className="bg-gray-700 animate-pulse w-1/4 h-full"></div>
                                    <div className="bg-gray-700 animate-pulse w-1/4 h-full"></div>
                                    <div className="bg-gray-700 animate-pulse animate-delay-50 w-1/4 h-full"></div>
                                    <div className="bg-gray-700 animate-pulse animate-delay-50 w-1/4 h-full"></div>
                                  </div>
                                  <FiLoader className="text-gray-500" />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    // className="inline-flex w-full justify-center bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 sm:ml-3 sm:w-auto"
                    className={`inline-flex w-full justify-center px-3 py-2 text-sm font-semibold ${
                      document.file === null || isLoading || data?.data != null
                        ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                        : "bg-gray-900 text-white shadow-sm hover:bg-gray-800"
                    } sm:ml-3 sm:w-auto`}
                    onClick={(e) => handleFileSelected(e)}
                    disabled={
                      document.file === null || isLoading || data?.data != null
                    }
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    // className="mt-3 inline-flex w-full justify-center  bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    className={`mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold ${
                      isLoading
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-gray-900"
                    } ${
                      isLoading
                        ? "bg-gray-200 hover:bg-gray-200"
                        : "shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    } sm:mt-0 sm:w-auto`}
                    onClick={() => {
                      setDocument(INITIAL_DOCUMENT);
                      setModal(false);
                      data.data = null;
                    }}
                    disabled={isLoading}
                  >
                    {data?.data ? "Close" : "Cancel"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FileUploadComponent;
