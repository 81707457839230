const BUTTON_TYPE = {
  OUTLINED: "outlined",
  CONTAINED: "contained",
};

const DIALOG_TYPE = {
  SUCCESS: "success",
  DEFAULT: "default",
  ERROR: "error",
  ACCESS_DENIED: "access denied",
};

// const DATE =
//   new Date().getFullYear +
//   " / " +
//   new Date().getMonth +
//   " / " +
//   new Date().getDate;

const DATE = new Date().toLocaleDateString();
const TIME = new Date().toLocaleTimeString();

export { BUTTON_TYPE, DIALOG_TYPE, DATE, TIME };
