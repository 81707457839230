import React from "react";
import Lottie from "lottie-react";

import restrictedAnimation from "../assets/restricted.json";
import ScreenWrapperComponent from "../components/common/ScreenWrapper.component";
import NavbarComponent from "../components/common/Navbar.component";

const UnAuthorizedPage = () => {
  return (
    <ScreenWrapperComponent>
      <NavbarComponent />
      <div className="bg-gray-50 h-full w-full flex flex-col justify-center items-center">
        <Lottie
          animationData={restrictedAnimation}
          loop={true}
          className="w-20"
        />
        <p className="text-md font-bold mt-4 text-gray-900 uppercase text-center">
          Oops! 403 - Access Denied!!!
        </p>
        <p className="text-sm font-medium mt-4 mx-8 text-center text-gray-500">
          Sorry, You don't have permission to do this operation.
        </p>
      </div>
    </ScreenWrapperComponent>
  );
};

export default UnAuthorizedPage;
