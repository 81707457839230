import React from "react";
import Lottie from "lottie-react";

import restrictedAnimation from "../../../assets/restricted.json";

const AccessDeniedComponent = () => {
  return (
    <div className="w-full h-full flex flex-col items-center m-2">
      <Lottie
        animationData={restrictedAnimation}
        loop={true}
        className="w-20"
      />
      <p className="text-md font-bold mt-4 text-gray-900 uppercase">
        Oops ! 403 - ACCESS DENIED
      </p>
      <p className="text-sm font-medium mt-4 mx-8 text-center text-gray-500">
        You are not subscribed to this service
      </p>
    </div>
  );
};

export default AccessDeniedComponent;
