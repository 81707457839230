import React from "react";
import TrainingComponent from "../components/training/Training.component";
import PageWrapperComponent from "../components/common/PageWrapper.component";

const TrainingPage = () => {
  return (
    <PageWrapperComponent>
      <TrainingComponent />
    </PageWrapperComponent>
  );
};

export default TrainingPage;
