import React from "react";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { IoChevronForward } from "react-icons/io5";

const UserItemComponent = ({ user, selectedUser, setSelectedUser }) => {
  return (
    <div
      className={`w-full flex justify-between items-center border-b-[1px] border-gray-300 p-4 py-3 cursor-pointer  ${
        selectedUser?.id === user?.id ? "bg-gray-300" : "hover:bg-gray-200"
      }`}
      onClick={() => setSelectedUser(user)}
    >
      <div className="flex items-center">
        <HiOutlineUserCircle size={28} />
        <div className="flex flex-col ms-2">
          <p className="text-md text-gray-900">{user.name}</p>
          <p className="text-xs text-gray-700">{user.email}</p>
        </div>
      </div>
      <IoChevronForward size={14} className="text-gray-500" />
    </div>
  );
};

export default UserItemComponent;
