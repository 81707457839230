import { QueryClient, QueryClientProvider } from "react-query";
import { UserProvider } from "./context/useAppContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";

import LoginPage from "./pages/Login.page";
import SharedLayout from "./layouts/SharedLayout";
import RegisterPage from "./pages/Register.page";
import ProtectedRoute from "./guard/ProtectedRoute";
import InvalidPage from "./pages/Invalid.page";
import DashboardPage from "./pages/Dashboard.page";
import ChatPage from "./pages/Chat.page";
import DocumentPage from "./pages/Document.page";
import ManageUserPage from "./pages/ManageUser.page";
import TrainingPage from "./pages/Training.page";
import { DialogProvider } from "./context/useDialogContext";
import { LoaderProvider } from "./context/useLoaderContext";
import RestrictDesktopView from "./layouts/RestrictDesktopView";
import UnAuthorizedPage from "./pages/UnAuthorized.page";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <RestrictDesktopView>
          <UserProvider>
            <DialogProvider>
              <LoaderProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/" element={<SharedLayout />}>
                      <Route
                        index
                        element={
                          <ProtectedRoute>
                            <DashboardPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/document"
                        element={
                          <ProtectedRoute>
                            <DocumentPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/training"
                        element={
                          <ProtectedRoute>
                            <TrainingPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/manageUser"
                        element={
                          <ProtectedRoute>
                            <ManageUserPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/chat"
                        element={
                          <ProtectedRoute>
                            <ChatPage />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="/unauthorized"
                      element={<UnAuthorizedPage />}
                    />
                    <Route path="*" element={<InvalidPage />} />
                  </Routes>
                </BrowserRouter>
              </LoaderProvider>
            </DialogProvider>
          </UserProvider>
        </RestrictDesktopView>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
