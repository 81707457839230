import React from "react";

const ScreenWrapperComponent = ({ children, className }) => {
  return (
    <main className={`${className} flex flex-col h-screen w-full bg-gray-900 `}>
      {children}
    </main>
  );
};

export default ScreenWrapperComponent;
