import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { RiRobot2Line } from "react-icons/ri";

import { DRAWER_ITEMS } from "../../constants";

const DrawerComponent = () => {
  return (
    <div className="flex flex-col justify-between w-80 h-full bg-gray-900 text-white pt-32">
      <div className="flex flex-col items-start w-full">
        {DRAWER_ITEMS.map((item) => (
          <NavLink
            key={item.label}
            to={item.url}
            className={({ isActive }) =>
              isActive
                ? "flex cursor-pointer w-full items-center bg-gray-800 py-3 px-4"
                : "flex cursor-pointer w-full items-center py-3 px-4"
            }
          >
            {item.icon}
            <p className="text-sm ms-2 uppercase">{item.label}</p>
          </NavLink>
        ))}
      </div>
      <NavLink className="flex cursor-pointer items-center p-4 ">
        <RiRobot2Line />
        <p className="text-sm ms-2 uppercase">PVH Bot</p>
      </NavLink>
    </div>
  );
};

export default DrawerComponent;
