import React from "react";
import { BUTTON_TYPE } from "../../constants";

const ButtonComponent = ({
  label,
  variant = BUTTON_TYPE.CONTAINED,
  className = "",
  onClick = () => {},
  icon = null,
}) => {
  if (variant === BUTTON_TYPE.OUTLINED) {
    return (
      <div
        className={`flex py-1.5 items-center justify-center border bg-white text-gray-900 border-gray-300 cursor-pointer rounded-none px-2 ${className}`}
        onClick={() => onClick()}
      >
        {icon != null ? <div className="me-2">{icon}</div> : ""}
        <p className="text-sm">{label}</p>
      </div>
    );
  }
  if (variant === BUTTON_TYPE.CONTAINED)
    return (
      <div
        className={`flex py-1.5 items-center justify-center border bg-gray-900 text-white cursor-pointer rounded-none px-2 ${className}`}
        onClick={() => onClick()}
      >
        {icon != null ? <div className="me-2">{icon}</div> : ""}
        <p className="text-sm">{label}</p>
      </div>
    );
};

export default ButtonComponent;
